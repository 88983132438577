export const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "16px",
      borderRadius: "5px",
    },
  },
  defaultProps: {
    variant: "contained",
  },
  variants: [
    {
      props: { variant: "outlined" },
      style: ({ theme }) => {
        return {
          backgroundColor: "white",
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          fontWeight: "normal",
        };
      },
    },
    {
      props: { variant: "contained" },
      style: ({ theme }) => {
        return {
          backgroundColor: theme.palette.primary.main,
          color: "white",
          fontWeight: "normal",
          border: `1px solid #008037`,
          padding: "5px 16px",
        };
      },
    },
  ],
};
