import {
  CURRENT_EMPLOYEE_FAILURE,
  CURRENT_EMPLOYEE_SUCCESS,
  EMPLOYEE_CREATE_FAILURE,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_DELETE_FAILURE,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_LOGIN_FAILURE,
  EMPLOYEE_LOGIN_REQUEST,
  EMPLOYEE_LOGIN_SUCCESS,
  RESTAURANT_EMPLOYEES_FAILURE,
  RESTAURANT_EMPLOYEES_REQUEST,
  RESTAURANT_EMPLOYEES_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  employee: null,
  error: null,
};
export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESTAURANT_EMPLOYEES_REQUEST:
      return { ...state, loading: true };
    case RESTAURANT_EMPLOYEES_SUCCESS:
      return { ...state, employee: action.payload, loading: false };
    case RESTAURANT_EMPLOYEES_FAILURE:
      return { ...state, employee: null, loading: false };

    case EMPLOYEE_LOGIN_REQUEST:
      return { ...state, loading: true };
    case EMPLOYEE_LOGIN_SUCCESS:
      return { ...state, employee: action.payload, loading: false };
    case EMPLOYEE_LOGIN_FAILURE:
      return { ...state, employee: null, loading: false };

    case EMPLOYEE_CREATE_REQUEST:
      return { ...state, loading: true };
    case EMPLOYEE_CREATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case EMPLOYEE_CREATE_FAILURE:
      return { ...state, data: null, error: action.payload, loading: false };

    case EMPLOYEE_DELETE_REQUEST:
      return { ...state, loading: true };
    case EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,

        loading: false,
      };
    case EMPLOYEE_DELETE_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case CURRENT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload,
        error: null,
        loading: false,
      };
    case CURRENT_EMPLOYEE_FAILURE:
      return {
        ...state,
        employee: null,
        error: action.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};
