import React, { useEffect } from "react";
import ky from "ky";
import { SET_LOADER } from "src/redux/actions";
import { SET_USER_DATA, SET_USER_DATA_FAIL } from "src/views/pages/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import localStorageConstants from "src/constants/localStorageConstants";

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem(localStorageConstants.token);

  const user = useSelector((state) => state.auth?.userDetails);

  useEffect(() => {
    if (!!token && !user) {
      ky.post(`${process.env.REACT_APP_BASE_URL}users/getByToken`, {
        json: {
          token,
        },
      })
        .json()
        .then((response) => {
          if (response?.success) {
            dispatch({ type: SET_LOADER, payload: false });

            dispatch({
              type: SET_USER_DATA,
              payload: response.data,
            });
          } else {
            dispatch({ type: SET_LOADER, payload: false });
            toast.error("Wrong Credentials");
            dispatch({ type: SET_USER_DATA_FAIL });
          }
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, payload: false });
          dispatch({ type: SET_USER_DATA_FAIL });
          toast.error("Network Error");
        });
    }
  }, []);

  return <>{children}</>;
};
