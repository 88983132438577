import { SET_USER_DATA, SET_RES_DATA, SET_USER_DATA_FAIL } from "./actions";

const initialState = {
  userDetails: {},
  restaurantDetails: {},
  isAuthenticated: false,
};

const auth = (state = initialState, action) => {
  console.log(action.payload, "action.payload");
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, userDetails: action.payload, isAuthenticated: true };
    case SET_USER_DATA_FAIL:
      return { ...state, isAuthenticated: false };
    case SET_RES_DATA:
      return { ...state, restaurantDetails: action.payload };
    default:
      return state;
  }
};
export default auth;
