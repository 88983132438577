import * as yup from "yup";

export const finalOrderValidation = yup.object().shape({
  delievery_type: yup.string().required("This field is required").nullable(),
  payment_via: yup.string().required("This field is required").nullable(),
  address: yup
    .string()
    .ensure()
    .when("delievery_type", (deliveryType, schema) => {
      if (deliveryType === "Delievery") {
        return schema.required("This field is required");
      }
      return schema;
    }),
});
