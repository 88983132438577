import React, { useState, useEffect } from 'react';
import './style.css';
import { GrSubtract, GrAdd } from 'react-icons/gr';
import ky from 'ky';
import { SET_LOADER } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import Images from 'src/assets/images';

function ShowItems({
  setRefresh,
  refresh,
  isAllowOnTableOrder,
  userDetails,
  cartItemss,
  setCartItemss,
}) {
  const [menuItem, setMenuItem] = useState([]);
  const [cartItems, getCartItems] = useState([]);
  const [showCategory, setShowCategory] = useState();

  const category_name = useSelector((state) => state?.commonReducer?.id);
  const userId = useSelector((state) => state?.auth?.userDetails?._id);

  console.log(userId, 'userDetails._id');

  //   let updatedMenu = menuItem
  //   ?.map((obj) => {
  //     return cartItems.find((o) => o?.items?.[0]?._id === obj?._id) || obj;
  //   })
  //   .filter((ok) => ok?.category_name === category_name);

  // console.log(updatedMenu, "updatedMenu");

  // function itemExists(id) {
  //   return cartItems.some(function (el) {
  //     return el?.items?.[0]?._id === id;
  //   });
  // }

  const dispatch = useDispatch();

  console.log(menuItem, 'menuItem');
  console.log(cartItems, 'cartItems');

  useEffect(() => {
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}menu/get`, {
      json: { user_id: userDetails._id },
    })
      .json()
      .then((response) => {
        console.log(response?.data, 'response?.data');
        setMenuItem(response?.data);
        console.log(userDetails._id, 'userDetailsx');
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, 'error');
      });

    ky.post(`${process.env.REACT_APP_BASE_URL}cart/get`, {
      json: { user_id: userDetails._id },
    })
      .json()
      .then((response) => {
        getCartItems(response?.data);
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, 'error');
      });

    ky.post(`${process.env.REACT_APP_BASE_URL}category/get`, {
      json: { user_id: userDetails._id },
    })
      .json()
      .then((response) => {
        setShowCategory(response?.data);
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, 'error');
      });
  }, [refresh]);

  function getCartId(itemIdtoPass) {
    return cartItems.find((o) => o?.items?.[0]?._id === itemIdtoPass)?._id;
  }

  function alreadyAddQuan(itemId) {
    const cart = cartItemss;
    cart[cartItemss.findIndex((el) => el._id === itemId)].quantity += 1;
    setCartItemss(cart);
    setRefresh(refresh + 1);

    /*console.log(itemId, quantity, 'checkItemDono');
    dispatch({ type: SET_LOADER, payload: true });

    const cartDataToSend = {
      _id: getCartId(itemId),
      item_id: itemId,
      user_id: userDetails._id,
      quantity: quantity + 1,
    };
    const menuDataToSend = {
      _id: itemId,
      user_id: userDetails._id,
      quantity: quantity + 1,
    };

    // console.log(cartDataToSend,menuDataToSend,"menuDataToSend")

    const incCart = axios.post(
      `${process.env.REACT_APP_BASE_URL}cart/add`,
      cartDataToSend
    );
    const incItem = axios.post(
      `${process.env.REACT_APP_BASE_URL}menu/add`,
      menuDataToSend
    );

    axios
      .all([incCart, incItem])
      .then(
        axios.spread((data1, data2) => {
          setRefresh(refresh + 1);
          toast.success('Item updated succesfully');
          dispatch({ type: SET_LOADER, payload: false });
        })
      )
      .catch((err) => {
        dispatch({ type: SET_LOADER, payload: false });
        toast.error(err);
      });*/
  }

  function alreadySubtractQuan(itemId) {
    const cart = cartItemss;
    if (cart[cartItemss.findIndex((el) => el._id === itemId)].quantity == 1) {
      const newCart = cart.filter((el) => el._id !== itemId);
      setCartItemss(newCart);
      console.log(cartItemss);
    } else {
      cart[cartItemss.findIndex((el) => el._id === itemId)].quantity -= 1;
      setCartItemss(cart);
      setRefresh(refresh + 1);
    }

    //setRefresh(refresh + 1);
    /*console.log(itemId, quantity, 'plssss 622f8e43e189b686e60606a8');

    dispatch({ type: SET_LOADER, payload: true });

    const cartDataToSend =
      quantity < 2
        ? {
            _id: getCartId(itemId),
            item_id: itemId,
            user_id: userDetails._id,
            quantity: 0,
            is_deleted: true,
          }
        : {
            _id: getCartId(itemId),
            item_id: itemId,
            user_id: userDetails._id,
            quantity: quantity - 1,
          };

    const menuDataToSend = {
      _id: itemId,
      user_id: userDetails._id,
      quantity: quantity - 1,
    };

    // console.log(cartDataToSend,menuDataToSend,"menuDataToSend")

    const incCart = axios.post(
      `${process.env.REACT_APP_BASE_URL}cart/add`,
      cartDataToSend
    );
    const incItem = axios.post(
      `${process.env.REACT_APP_BASE_URL}menu/add`,
      menuDataToSend
    );

    axios
      .all([incCart, incItem])
      .then(
        axios.spread((data1, data2) => {
          setRefresh(refresh + 1);
          toast.success('Item updated succesfully');
          dispatch({ type: SET_LOADER, payload: false });
        })
      )
      .catch((err) => {
        dispatch({ type: SET_LOADER, payload: false });
        toast.error(err);
      });*/
  }

  function AddQuan(item) {
    //dispatch({ type: SET_LOADER, payload: true });

    /*const cartDataToSend = {
      item_id: id,
      user_id: userDetails._id,
      quantity: 1,
    };*/

    const i = item;

    i.quantity = 1;

    const cart = cartItemss;
    cart.push(i);

    setCartItemss(cart);
    console.log(cartItemss);
    setRefresh(refresh + 1);

    /*const menuDataToSend = { _id: id, user_id: userDetails._id, quantity: 1 };

    const incCart = axios.post(
      `${process.env.REACT_APP_BASE_URL}cart/add`,
      cartDataToSend
    );
    const incItem = axios.post(
      `${process.env.REACT_APP_BASE_URL}menu/add`,
      menuDataToSend
    );

    axios
      .all([incCart, incItem])
      .then(
        axios.spread((data1, data2) => {
          setRefresh(refresh + 1);
          toast.success('Item added succesfully');
          dispatch({ type: SET_LOADER, payload: false });
        })
      )
      .catch((err) => {
        dispatch({ type: SET_LOADER, payload: false });
        toast.error(err);
      });*/
  }

  return (
    <div className='selectItems'>
      {/* <div className="second-head">
        <p className="inner-head-text">Items</p>
      </div> */}
      <div className='items-container'>
        {menuItem &&
          menuItem
            ?.filter(
              (ok) =>
                ok?.category_name === category_name && ok?.is_available === true
            )
            ?.map((item, index) => {
              return !cartItemss.some((el) => el._id === item._id) ? (
                <div key={index} className='items-card'>
                  {item?.item_url ? (
                    <img
                      src={item?.item_url}
                      width='75'
                      height='75'
                      style={{ 'border-radius': '10px' }}
                    />
                  ) : (
                    ''
                  )}

                  <div className='item-details'>
                    <p
                      style={{
                        fontWeight: '700',
                        fontFamily: 'Nunito, sans-serif',
                      }}
                    >
                      {item?.is_veg ? (
                        <img
                          src={'/assets/images/Veg.svg'}
                          width='15'
                          height='15'
                        />
                      ) : (
                        <img
                          src={'/assets/images/Nonveg.svg'}
                          width='15'
                          height='15'
                        />
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {item?.item_name}
                    </p>
                    <p style={{ fontSize: '10px' }}>{item?.description}</p>
                    <p
                      style={{
                        fontWeight: '400',
                        fontFamily: 'Nunito, sans-serif',
                        'margin-top': '5px',
                      }}
                    >
                      {'\u20B9'} {item?.display_price}
                    </p>
                  </div>
                  {isAllowOnTableOrder ? (
                    <div className='item-quantity'>
                      <p style={{ fontSize: '15px' }}>Add</p>
                      <GrAdd
                        style={{ paddingLeft: '10px', fontSize: '23px' }}
                        onClick={() => AddQuan(item)}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div key={index} className='items-card'>
                  {item?.item_url ? (
                    <img
                      src={item?.item_url}
                      width='75'
                      height='75'
                      style={{ 'border-radius': '10px' }}
                    />
                  ) : (
                    ''
                  )}
                  <div className='item-details'>
                    <p
                      style={{
                        fontWeight: '700',
                        fontFamily: 'Nunito, sans-serif',
                      }}
                    >
                      {item?.is_veg ? (
                        <img
                          src={'/assets/images/Veg.svg'}
                          width='15'
                          height='15'
                        />
                      ) : (
                        <img
                          src={'/assets/images/Nonveg.svg'}
                          width='15'
                          height='15'
                        />
                      )}
                      &nbsp;&nbsp;
                      {item?.item_name}
                    </p>

                    <p
                      style={{
                        fontWeight: '400',
                        fontFamily: 'Nunito, sans-serif',
                      }}
                    >
                      {'\u20B9'} {item?.display_price}
                    </p>
                  </div>

                  {isAllowOnTableOrder ? (
                    <div className='item-quantity'>
                      <GrSubtract
                        style={{ paddingRight: '10px', fontSize: '23px' }}
                        onClick={() =>
                          alreadySubtractQuan(item?._id, item?.quantity)
                        }
                      />
                      <p>
                        {
                          cartItemss[
                            cartItemss.findIndex((el) => el._id === item._id)
                          ].quantity
                        }
                      </p>
                      <GrAdd
                        style={{ paddingLeft: '10px', fontSize: '23px' }}
                        onClick={() => alreadyAddQuan(item?._id)}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default ShowItems;
