import React, { useState, useEffect, useContext } from "react";
import ShowCategories from "./categories";
// import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import ShowItems from "./items";
import Checkout from "./checkout";
import { useSelector, useDispatch } from "react-redux";
import ky from "ky";
import { SET_LOADER } from "src/redux/actions";
import moment from "moment";
import { FaBell } from "react-icons/fa";
import "./bell.css";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import { IoCallOutline } from "react-icons/io5";
import { RestaurantContext } from "src/provider/RestaurantProvider";
import Grid from "@mui/material/Grid";
import MenuItem from "./ItemCard";
import Box from "@mui/material/Box";
import { Button, Drawer } from "@mui/material";
// import { useState } from 'react';
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";

function CustomerDashboard() {
  const { restaurant, setRestaurant } = useContext(RestaurantContext);

  const [cartItemss, setCartItemss] = useState([]);
  const [key, setKey] = useState(-1);
  const [anchor, setAnchor] = useState(false);

  const [refresh, setRefresh] = useState(0);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [bellActive, setBellActive] = useState(false);
  const { slug, id } = useParams();
  const [data, setData] = useState([]);

  console.log(restaurant, "restaurant");
  console.log(userDetails, "userDetails");
  console.log(slug, "slug");

  useEffect(() => {
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}restaurant/getCustomer`, {
      json: { restaurant_slug: slug },
    })
      .json()
      .then((response) => {
        console.log(response?.data?.[0], "get ressss");
        if (response?.data?.length > 0) {
          setRestaurant(response?.data?.[0]);
        }
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, "error");
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}users/get`, {
      json: { _id: restaurant?.user_id },
    })
      .json()
      .then((response) => {
        console.log(response?.data?.[0], "get ressss");
        setUserDetails(response?.data?.[0]);
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, "error");
        toast.error(error);
      });
  }, [restaurant?.user_id]);

  useEffect(() => {
    // dispatch({ type: SET_LOADER, payload: true });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}menu/get/categoryWise/item`,
        {
          user_id: userDetails?._id,
        },
        config
      )
      .then((response) => {
        console.log(response?.data?.data, "response for ");
        setData(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, [userDetails?._id]);

  return (
    <div className="pageCart">
      {/* <div className="bell-div" onClick={() => setBellActive(true)}>
        <FaBell
          className={`bell ${bellActive ? "active" : null}`}
          onClick={() => {
            toast.success("request sent");
            setBellActive(true);
          }}
        />
      </div> */}

      <div className="headerBox my-1 shadow">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {restaurant?.restaurant_cover_image ? (
            <img
              src={restaurant?.restaurant_cover_image}
              width="150"
              height="100"
              className="item-img2 "
            />
          ) : (
            <img
              src="https://zymeal.com/storage/restaurant/cover/16311806716139d77f480e5.png"
              width="150"
              height="100"
              className="item-img2 "
            />
          )}
          {restaurant?.restaurant_image ? (
            <img
              src={restaurant?.restaurant_image}
              width="120"
              height="100"
              className="px-2"
            />
          ) : (
            <img
              src="https://zymeal.com/storage/restaurant/logo/16311806716139d77f2ac93.png"
              width="120"
              height="100"
              className="px-2"
            />
          )}
          <p className="headerBoxHeading">{restaurant?.restaurant_name}</p>
        </div>
        <div>
          <div className="headerBoxFlex">
            {/* <div className="headerBoxFlex2 active">
              <AiOutlineClockCircle size={20} />
              <p className="px-1">
                {moment(restaurant?.start_time).format("hh:mm a")} to{" "}
                {moment(restaurant?.end_time).format("hh:mm a")}
              </p>
            </div> */}

            <div
              style={{
                display: "flex",
              }}
            >
              <a
                href={`https://maps.google.com/?q=${restaurant?.latitude},${restaurant?.longitude}`}
                target="_blank"
                className="headerBoxFlex2"
              >
                <ImLocation size={20} />
                <p className="px-1">Direction</p>
              </a>
              {userDetails?.phone_number && (
                <a
                  href={`tel:${userDetails?.phone_number}`}
                  className="headerBoxFlex2 px-2"
                >
                  <IoCallOutline size={20} />
                  <p className="px-1">Call Now</p>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="rowData mx-3 ">
        {/* {userDetails && (
          <ShowCategories
            setRefresh={setRefresh}
            refresh={refresh}
            userDetails={userDetails}
          />
        )}
        {userDetails && (
          <ShowItems
            setRefresh={setRefresh}
            refresh={refresh}
            isAllowOnTableOrder={restaurant.is_allowOnTableOrder}
            userDetails={userDetails}
            cartItemss={cartItemss}
            setCartItemss={setCartItemss}
          />
        )} */}

        {userDetails && (
          <Checkout
            setRefresh={setRefresh}
            refresh={refresh}
            id={id}
            restaurant={restaurant}
            userDetails={userDetails}
            cartItemss={cartItemss}
            setCartItemss={setCartItemss}
          />
        )}

        <Grid container spacing={2} height="100vh" className="main_ui mt-5">
          <Grid
            className="Main_menu"
            item
            lg={3}
            md={3}
            sm={3}
            xs={12}
            height="100%"
            borderRight="1px solid #ddd"
          >
            <div
              style={{
                fontWeight: "600",
                maxHeight: "100vh",
                overflow: "scroll",
              }}
            >
              <div
                style={{
                  width: "70%",
                  margin: "auto",
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "1.7em",
                }}
              >
                <ul className="Menu_heading" style={{ listStyle: "none" }}>
                  <li
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "1.7em",
                    }}
                  >
                    Menu
                  </li>
                </ul>
              </div>
              <div style={{ width: "70%", margin: "auto" }}>
                <ul className="Menu_list" style={{ listStyle: "none" }}>
                  {data.map((item, idx) => {
                    return (
                      <a href={`#${item?._id}`}>
                        {" "}
                        <li
                          style={{ textTransform: "capitalize" }}
                          className={key == idx ? "active" : ""}
                          onClick={() => {
                            setKey(idx);
                            console.log(idx, key);
                          }}
                        >
                          {item?._id}
                        </li>
                      </a>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            xs={12}
            sx={{
              maxHeight: "100vh",
              overflowY: "scroll",
              scrollBehavior: "smooth",
            }}
          >
            {data.map((item) => {
              return (
                <div id={item?._id}>
                  <h2
                    style={{
                      paddingLeft: "1em",
                      fontSize: "1.5em",
                      marginBlockStart: "1.5em",
                      marginBlockEnd: "1.5em",
                      fontWeight: "900",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?._id}
                    <br />
                    <span
                      style={{
                        fontSize: "0.7em",
                        marginBlockStart: "0.83em",
                        marginBlockEnd: "0.83em",
                        fontWeight: "500",
                        color: "gray",
                      }}
                    >
                      {item?.items?.length} items
                    </span>
                  </h2>

                  <Box width={"90%"} margin={"auto"}>
                    <Grid container spacing={4}>
                      {item?.items.map((item) => {
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {" "}
                            <MenuItem
                              cart={cartItemss}
                              setCart={setCartItemss}
                              item={item}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </div>
              );
            })}
          </Grid>
        </Grid>
        <Drawer
          anchor={"bottom"}
          open={anchor}
          onClose={() => setAnchor(false)}
          className="drawer_main"
        >
          <div style={{ width: "70%", margin: "auto" }}>
            <ul className="Menu_list" style={{ listStyle: "none" }}>
              {data.map((item, idx) => {
                return (
                  <a href={`#${item?._id}`}>
                    {" "}
                    <li
                      className={key == idx ? "active" : ""}
                      onClick={() => {
                        setKey(idx);
                        setAnchor(false);
                      }}
                      style={{
                        borderBottom: "1px solid #ddd",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        textTransform: "capitalize",
                      }}
                    >
                      {item?._id}
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>
        </Drawer>
        <button
          className={`toggle_btn ${cartItemss.length > 0 ? "toggleUp" : null} `}
          onClick={() => setAnchor(true)}
        >
          <MenuIcon fontSize="medium" sx={{ color: "white" }} />
          <span style={{ color: "white", fontSize: "11px", fontWeight: "700" }}>
            Menu
          </span>
        </button>
      </div>
    </div>
  );
}

export default CustomerDashboard;
