import React, { Component, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./scss/style.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./components/loader";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import customerRoutes from "./routes/customerRoutes";
import { SET_LOADER } from "src/redux/actions";
import ky from "ky";
import { SET_RES_DATA, SET_USER_DATA } from "./views/pages/actions";
import { OrderContextProvider } from "./provider/OrderContextProvider";
import { AuthProvider } from "./provider/AuthProvider";
import { RestaurantProvider } from "./provider/RestaurantProvider";
import "react-datetime/css/react-datetime.css";
import { getRestaurantEmployees } from "./redux/employee/actions";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import axios from "axios";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login"));
const ChangePassword = React.lazy(() => import("./views/pages/changePassword"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgotPassword = React.lazy(() => import("./views/pages/forgotPassword"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Lock = React.lazy(() => import("./views/pages/lock"));

const Loading = ({ loading }) => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};

function App() {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state?.auth?.userDetails?._id);
  const revolving = useSelector((state) => state.commonReducer.loader);

  useEffect(() => {
    if (user_id) {
      dispatch({ type: SET_LOADER, payload: true });
      ky.post(`${process.env.REACT_APP_BASE_URL}restaurant/get`, {
        json: { user_id: user_id },
      })
        .json()
        .then(async (response) => {
          if (response?.data?.length > 0) {
            const restaurant = response?.data?.[0];
            dispatch({ type: "Res_Exists", exists: true });
            dispatch({
              type: SET_RES_DATA,
              payload: restaurant,
            });
            if (restaurant._id) {
              dispatch(
                getRestaurantEmployees({ restaurantId: restaurant._id })
              );

              await axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}billing/count?id=${user_id}`
                )
                .then((response) => {
                  localStorage.setItem("count", response?.data?.data || 0);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            dispatch({ type: SET_LOADER, payload: false });
            return;
          }
          dispatch({ type: "Res_Exists", exists: false });
          dispatch({ type: SET_LOADER, payload: false });
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, payload: false });
          dispatch({ type: "Res_Exists", exists: false });
          toast.error(error);
        });
    }
  }, [user_id]);

  return (
    <div>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <RestaurantProvider>
              <OrderContextProvider>
                <Loader loading={revolving} />
                <ToastContainer
                  position="bottom-left"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <React.Suspense fallback={<Loader loading={revolving} />}>
                  <Routes>
                    {customerRoutes?.map((route, idx) => {
                      return (
                        route.component && (
                          <Route
                            key={idx}
                            path={route.path}
                            name={route.name}
                            element={<route.component />}
                          />
                        )
                      );
                    })}

                    <Route
                      exact
                      path="/login"
                      name="Login Page"
                      element={<Login />}
                    />
                    <Route
                      exact
                      path="/changePassword"
                      name="Change Password"
                      element={<ChangePassword />}
                    />
                    <Route
                      exact
                      path="/forgotPassword"
                      name="Change Password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      exact
                      path="/register"
                      name="Register Page"
                      element={<Register />}
                    />
                    <Route
                      exact
                      path="/404"
                      name="Page 404"
                      element={<Page404 />}
                    />
                    <Route
                      exact
                      path="/500"
                      name="Page 500"
                      element={<Page500 />}
                    />
                    <Route path="*" element={<DefaultLayout />} />
                    <Route exact path="/lock" name="Lock" element={<Lock />} />
                  </Routes>
                </React.Suspense>
              </OrderContextProvider>
            </RestaurantProvider>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
export { Loading };

// console.log(gap([1,2,3,4,5,6],2));
// onContextMenu={(e)=> e.preventDefault()} //
