import Logo from "./Logo.png"
import LogoTwo from "./logo2.png"
import Table from "./table.jpg"
import Plus from "./plus.png"
import Edit from "./edit.png"
import HomeDelievery from "./home-delievery.png"
import Pending from "./pending.png"
import Preparing from "./preparing.png"
import Ready from "./ready.png"
import Avatar from "./avatars/1.jpg"
import Register from "./register.png"
import NoImage from "./noImage.png"
import EditIcon from "./editicon.png"

const Images = {
    logo:Logo,
    LogoTwo:LogoTwo,
    Table:Table,
    Plus:Plus,
    Edit:Edit,
    HomeDelievery:HomeDelievery,
    Ready:Ready,
    Preparing:Preparing,
    Pending:Pending,
    Avatar:Avatar,
    Register:Register,
    NoImage:NoImage,
    EditIcon:EditIcon
}

export default Images