import React from "react";
import CustomerDashboard from "../views/customer/dashboard";
// const CustomerDashboard = React.lazy(() => import('../views/customer/dashboard'))

const customerRoutes = [
  {
    path: "/customer/:slug",
    exact: true,
    name: "customer-slug",
    component: CustomerDashboard,
  },
  {
    path: "/customer/:slug/:id",
    exact: true,
    name: "customer-slug-id",
    component: CustomerDashboard,
  },
];

export default customerRoutes;
