export const palette = {
  primary: {
    main: "#008037",
  },
  secondary: {
    main: "#ffffff",
  },
  background: {
    default: "#ffffff",
  },
};
