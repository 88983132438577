export let delieveryTypeSelect = [
    {key:"Select the Pay Online type ",value:""},
      {key:"On Table",value:"On Table"},
      {key:"Takeaway",value:"Takeaway"},
      {key:"Delievery",value:"Delievery"},
    ]

export let paymentSelect = [
    {key:"Select the payment method",value:""},
    
      {key:"Pay on Counter",value:"Pay on Counter"},
      {key:"Pay Online",value:"Pay Online"},
    ]