import React, { useEffect, useState } from "react";
import "./style.css";
import ky from "ky";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORY_ID, SET_LOADER } from "src/redux/actions";
import { useMediaQuery } from "react-responsive";
import Images from "src/assets/images";

function ShowCategories({ userDetails }) {
  const [showCategory, setShowCategory] = useState();
  const isCategory = useMediaQuery({ query: "(max-width:800px)" });
  const dispatch = useDispatch();
  const category_name = useSelector((state) => state?.id);
  const userId = useSelector((state) => state?.auth?.userDetails?._id);

  const mapData = [...Array(8).keys()];

  console.log(showCategory, "showCategory");

  useEffect(() => {
    console.log(userId, "userId");
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}category/get`, {
      json: { user_id: userDetails._id },
    })
      .json()
      .then((response) => {
        setShowCategory(response?.data);
        console.log(userDetails._id, "userDetailss");
        console.log(response.data, "response.data");
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, "error");
      });
  }, []);

  return (
    <div className="categoryBox">
      {/* <div className="head">
        <p className="inner-head-text">Categories</p>
      </div> */}
      {console.log("showCategory", showCategory)}
      <div className="cardContainer">
        {showCategory
          ?.filter((item) => item?.is_available === true)
          ?.map((item, index) => {
            return (
              <div style={{ textAlign: "center" }}>
                <div
                  key={index}
                  className={`category-card ${
                    item?.category_name === category_name
                      ? "active-category"
                      : null
                  } `}
                  onClick={() =>
                    dispatch({
                      type: CATEGORY_ID,
                      payload: item?.category_name,
                    })
                  }
                >
                  <img
                    src={
                      item?.category_img ? item?.category_img : Images.NoImage
                    }
                    width="70"
                    height="70"
                  />
                  {isCategory ? null : (
                    <p className="categoryText">{item?.category_name}</p>
                  )}
                </div>

                {isCategory ? (
                  <div style={{ marginTop: "10px" }}>
                    <p className="cat-text">{item?.category_name}</p>
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
      {/* {mapData.map((item, index) => {
        return (
          <div key={index} className={`category-card ${"active-category"} `}>
            <img
              src="https://zymeal.com/storage/restaurant/logo/default.png"
              width="70"
              height="70"
            />
            <p className="categoryText">Hello</p>
          </div>
        );
      })} */}
    </div>
  );
}

export default ShowCategories;
