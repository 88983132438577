import { CardMedia } from "@mui/material";
import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Default from "src/assets/images/defaultfood.jpg";
function ItemCard({ item, cart, setCart }) {
  const [total, setTotal] = useState(0);
  // console.log(item, "item");

  const AddQuan = (item) => {
    console.log(item);
    const i = item;

    i.quantity = 1;

    const cart2 = cart;
    cart2.push(i);
    console.log(cart2);

    setCart([...cart2]);
  };

  const alreadySubtractQuan = (itemId) => {
    const cart2 = cart;
    if (cart2[cart.findIndex((el) => el._id === itemId)].quantity == 1) {
      const newCart = cart2.filter((el) => el._id !== itemId);
      setCart([...newCart]);
    } else {
      cart2[cart2.findIndex((el) => el._id === itemId)].quantity -= 1;
      setCart([...cart2]);
    }
  };

  const alreadyAddQuan = (itemId) => {
    const cart2 = cart;
    cart2[cart2.findIndex((el) => el._id === itemId)].quantity += 1;
    setCart([...cart2]);
  };
  return (
    <div className="menu_item_comp">
      <div className="item_disc">
        <div>
          {item?.is_veg ? (
            <img src={"/assets/images/Veg.svg"} height="20" />
          ) : (
            <img src={"/assets/images/Nonveg.svg"} height="20" />
          )}
        </div>
        <div>
          <h4
            style={{
              fontSize: "1.17em",
              marginBlockStart: ".5em",
              marginBlockEnd: ".5em",
              fontWeight: "600",
            }}
          >
            {item?.item_name}
          </h4>
        </div>
        <p>
          {" "}
          <h4 style={{ fontSize: "1em", color: "#6a6a6a" }}>
            {" "}
            ₹{item?.display_price}{" "}
          </h4>
        </p>
      </div>
      <div className="item_img">
        {item?.item_url ? (
          <img
            style={{
              borderRadius: "10px",
              objectFit: "cover",
              position: "relative",
            }}
            src={item?.item_url.replaceAll('"', "")}
            height={110}
            width={136}
            alt=""
            srcset=""
          ></img>
        ) : (
          <div className="relative"></div>
        )}
        <button
          className={`add_btn `}
          style={{ position: `${item?.item_url ? "absolute" : ""}` }}
        >
          {!cart.some((el) => el._id === item._id) ? (
            <span style={{ fontWeight: "500" }} onClick={() => AddQuan(item)}>
              {" "}
              ADD
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                <RemoveIcon onClick={() => alreadySubtractQuan(item?._id)} />
              </span>
              <span style={{ fontSize: "20px" }}>
                {cart[cart.findIndex((el) => el._id === item._id)].quantity}
              </span>
              <span>
                <AddIcon onClick={() => alreadyAddQuan(item?._id)} />
              </span>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}
export default ItemCard;
