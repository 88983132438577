import React, { useEffect, useState } from "react";
import "./style.css";
import Images from "src/assets/images";
import { GrSubtract, GrAdd } from "react-icons/gr";
import ky from "ky";
import { Offcanvas, Button } from "react-bootstrap";
import SimpleInput from "src/components/formFields/simpleInput";
import OtpInput from "react-otp-input";
import FinalOrder from "./finalOrder";
import { toast } from "react-toastify";
import { SET_LOADER } from "src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SET_USER_DATA } from "src/views/pages/actions";

function Checkout({
  setRefresh,
  refresh,
  id,
  restaurant,
  userDetails,
  cartItemss,
  setCartItemss,
}) {
  const [cartItems, setCartItems] = useState();
  const [showBottom, setShowBottom] = useState(0);
  const [otp, setOtp] = useState({ otp: "" });
  const [getOtp, setGetOtp] = useState();
  const [getNumber, setGetNumber] = useState();
  const dispatch = useDispatch();
  const [getName, setGetName] = useState("");
  const [getOtpId, setGetOtpId] = useState("");
  const userId = useSelector((state) => state?.auth?.userDetails?._id);

  console.log(otp?.otp, "check otp");

  console.log(getOtp, "getOtp");

  console.log(cartItems, "cartItems");

  useEffect(() => {
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}cart/get`, {
      json: { user_id: userDetails._id },
    })
      .json()
      .then((response) => {
        setCartItems(response?.data);
        dispatch({ type: SET_LOADER, payload: false });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, "error");
      });
  }, [cartItemss]);

  async function GetOtp() {
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}otp/send`, {
      json: {
        customer_phone_no: getNumber,
        customer_name: getName,
        user_id: userDetails._id,
      },
    })
      .json()
      .then((response) => {
        setGetOtp(response?.data?.otp);
        setGetOtpId(response?.data?._id);
        dispatch({ type: SET_LOADER, payload: false });
        setShowBottom(2);
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, "error");
      });
  }

  function checkOtp() {
    dispatch({ type: SET_LOADER, payload: true });
    ky.post(`${process.env.REACT_APP_BASE_URL}otp/verify`, {
      json: {
        customer_phone_no: getNumber,
        otp: otp.otp,
      },
    })
      .json()
      .then((response) => {
        return ky
          .post(`${process.env.REACT_APP_BASE_URL}customer/`, {
            json: {
              phone_number: getNumber,
              name: getName,
            },
          })
          .json()
          .then((res) => {
            toast.success("OTP verified succesfully");
            setShowBottom(3);
            dispatch({ type: SET_LOADER, payload: false });
            const { token, ...user } = res;
            if (!!token) {
              localStorage.setItem("token", token);
            }
            dispatch({
              type: SET_USER_DATA,
              payload: user,
            });
            setGetName(user.name);
            setGetNumber(user.phone_number);
            return res;
          });
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        toast.error("Check You OTP");
        console.log(error, "error");
      });
  }

  function getCartId(itemIdtoPass) {
    return cartItems.find((o) => o?.items?.[0]?._id === itemIdtoPass)?._id;
  }

  function alreadyAddQuan(itemId) {
    const cart = cartItemss;
    cart[cartItemss.findIndex((el) => el._id === itemId)].quantity += 1;
    setCartItemss(cart);
    setRefresh(refresh + 1);
  }

  function alreadySubtractQuan(itemId) {
    const cart = cartItemss;
    if (cart[cartItemss.findIndex((el) => el._id === itemId)].quantity == 1) {
      const newCart = cart.filter((el) => el._id !== itemId);
      setCartItemss(newCart);
      console.log(cartItemss);
    } else {
      cart[cartItemss.findIndex((el) => el._id === itemId)].quantity -= 1;
      setCartItemss(cart);
      setRefresh(refresh + 1);
    }
  }

  const sum = cartItemss
    ?.map((item) => item?.display_price * item?.quantity)
    .reduce((prev, curr) => prev + curr, 0);
  console.log(sum, "sum");

  const cartLength = cartItemss
    ?.map((item) => item?.quantity)
    .reduce((prev, curr) => prev + curr, 0);
  console.log(cartLength, "cartLength");

  return (
    <div className="cart-container">
      <Offcanvas
        show={showBottom === 1}
        onHide={() => setShowBottom(0)}
        placement="bottom"
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            style={{ margin: "auto", fontSize: "35px", fontWeight: 700 }}
          >
            Where Should We Contact You?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ margin: "auto" }}>
          <b>
            "Before you proceed, kindly provide your Whatsapp mobile number so
            that we can keep you updated with your order"
          </b>
          <SimpleInput
            placeholder="Enter your name"
            style={{ width: "40%", margin: "30px auto" }}
            label="Your Name"
            onChange={(e) => setGetName(e.target.value)}
            value={getName}
            required={true}
          />
          <SimpleInput
            placeholder="Enter your whatsapp Number"
            style={{ width: "40%", margin: "30px auto" }}
            label="Whatsapp Number"
            onChange={(e) => setGetNumber(e.target.value)}
            value={getNumber}
            required={true}
          />
          {console.log(getNumber && getNumber.length, "getNumber")}
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={GetOtp}
              variant="success"
              style={{ color: "white" }}
              disabled={
                getNumber
                  ? (getNumber && getNumber.length != 10) ||
                    (getName && getName.length === 0)
                    ? true
                    : false
                  : true
              }
            >
              Proceed
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showBottom === 2}
        onHide={() => setShowBottom(0)}
        placement="bottom"
      >
        <Offcanvas.Header>
          <Offcanvas.Title
            style={{ margin: "auto", fontSize: "35px", fontWeight: 700 }}
          >
            OTP Verification
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ margin: "auto" }}>
          <b>"Enter the OTP you received to +91{`${getNumber}`}"</b>
          <OtpInput
            value={otp?.otp}
            onChange={(otp) => setOtp({ otp })}
            numInputs={4}
            separator={<span className="mx-2 fs-3">-</span>}
            isInputNum
            containerStyle={{ justifyContent: "center", marginTop: "35px" }}
          />

          <p
            className="mt-5"
            style={{ color: "#008037" }}
            onClick={() => setShowBottom(1)}
          >
            Resend Otp
          </p>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={checkOtp}
              variant="success"
              style={{ color: "white", marginTop: "20px" }}
              disabled={otp && otp.length < 4 ? true : false}
            >
              Verify
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <FinalOrder
        setShowBottom={setShowBottom}
        showBottom={showBottom}
        otpId={getOtpId}
        id={id}
        restaurant={restaurant}
        phoneNumber={getNumber}
        name={getName}
        cartItems={cartItems}
        sum={sum}
        setCartItems={setCartItems}
        cartItemss={cartItemss}
        setCartItemss={setCartItemss}
      />

      {cartItemss?.length > 0 ? (
        <div className="checkout-container">
          <p className="checkout-container-text">{cartLength} item's</p>
          <p className="checkout-container-text">
            {"\u20B9"} {sum}
          </p>
          <p
            className="checkout-container-text pointer"
            onClick={() => (userId ? setShowBottom(3) : setShowBottom(1))}
          >
            View Order{" "}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Checkout;
