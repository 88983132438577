import React, { useEffect } from 'react';
import { Card, Row, Col, Button, Offcanvas } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import Images from 'src/assets/images';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import ky from 'ky';
import SimpleInput from 'src/components/formFields/simpleInput';
import { SET_LOADER } from 'src/redux/actions';
import { toast } from 'react-toastify';
// import ReactWhatsapp from "react-whatsapp";
import { finalOrderValidation } from './validation';
import { delieveryTypeSelect, paymentSelect } from './option';
import SimpleSelect from 'src/components/formFields/simpleSelect';
import { CFormLabel, CFormTextarea } from '@coreui/react';
import { postData } from 'src/utils/apiData';
import { orderTabValue } from 'src/views/order/utils';
import { wsClient as client } from 'src/apis/webSocketClient';

function FinalOrder(props) {
  process.env.REACT_APP_BASE_URL;

  const {
    showBottom = '',
    setShowBottom = {},
    otpId = '',
    id = '',
    restaurant = {},
    phoneNumber,
    name,
    setCartItems,
    cartItems,
    sum,
    cartItemss,
    setCartItemss,
  } = props;

  console.log(otpId, 'final otpId');

  const dispatch = useDispatch();

  const userId = useSelector((state) => state?.auth?.userDetails?._id);
  const user = useSelector((state) => state?.auth?.userDetails);

  useEffect(() => {
    if (userId && otpId) {
      dispatch({ type: SET_LOADER, payload: true });
      ky.post(`${process.env.REACT_APP_BASE_URL}cart/get`, {
        json: { user_id: userId },
      })
        .json()
        .then((response) => {
          setCartItems(response?.data);
          dispatch({ type: SET_LOADER, payload: false });
        })
        .catch((error) => {
          dispatch({ type: SET_LOADER, payload: false });
          console.log(error, 'error');
        });

      // ky.post(`${process.env.REACT_APP_BASE_URL}otp/get`, {
      //   json: { user_id: userId, _id: otpId },
      // })
      //   .json()
      //   .then((response) => {
      //     console.log(response?.data, "customer details");
      //     setCustomerDetails({
      //       ...customerDetails,
      //       name: response?.data?.[0]?.customer_name,
      //       number: response?.data?.[0]?.customer_phone_no,
      //     });
      //     dispatch({ type: SET_LOADER, payload: false });
      //   })
      //   .catch((error) => {
      //     dispatch({ type: SET_LOADER, payload: false });
      //     console.log(error, "error");
      //   });
    }
  }, [otpId]);

  useEffect(() => {
    client.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    if (restaurant?.is_allowDelievery == false) {
      let idx = delieveryTypeSelect.findIndex((x) => x.key == 'Delievery');
      if (idx != -1) delieveryTypeSelect.splice(idx, 1);
    }
    if (restaurant?.is_allowOnTableOrder == false) {
      let idx = delieveryTypeSelect.findIndex((x) => x.key == 'On Table');
      if (idx != -1) delieveryTypeSelect.splice(idx, 1);
    }
    if (restaurant?.is_payOnCounter == false) {
      let idx = paymentSelect.findIndex((x) => x.key == 'Pay on Counter');
      if (idx != -1) paymentSelect.splice(idx, 1);
    }
    if (restaurant?.is_payment_online == false) {
      let idx = paymentSelect.findIndex((x) => x.key == 'Pay Online');
      if (idx != -1) paymentSelect.splice(idx, 1);
    }
    if (restaurant?.is_takeawayOrder == false) {
      let idx = delieveryTypeSelect.findIndex((x) => x.key == 'Takeaway');
      if (idx != -1) delieveryTypeSelect.splice(idx, 1);
    }
  });

  // function SendToWhatsapp() {
  //   return (
  //     <ReactWhatsapp
  //       number={"+91" + customerDetails?.number}
  //       message="Hello World!!!"
  //     />
  //   );
  // }

  return (
    <div>
      <Offcanvas
        show={showBottom === 3}
        onHide={() => setShowBottom(0)}
        placement='bottom'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{ margin: 'auto', fontSize: '35px', fontWeight: 700 }}
          >
            Send Order
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body style={{ marginLeft: '40px', marginRight: '40px' }}>
          <Formik
            validationSchema={finalOrderValidation}
            initialValues={{
              delievery_type: '',
              name: user?.name || name,
              phone_number: user?.phone_number || phoneNumber,
              order_items: {},
              payment_via: '',
              table_no: '',
              address: '',
            }}
            autoComplete='off'
            onSubmit={(values) => {
              dispatch({ type: SET_LOADER, payload: true });
              if (values.table_no == '') {
                values.table_no = id;
              }

              ky.post(`${process.env.REACT_APP_BASE_URL}order/add`, {
                json: {
                  ...values,
                  order_items: cartItemss,
                  order_id: Math.floor(1000 + Math.random() * 9000),
                  grand_total: sum,
                  order_status: orderTabValue.pending,
                  user_id: restaurant.user_id,
                  customer_name: values?.name,
                  customer_phone_no: values?.phone_number,
                  customer_address: values?.address,
                },
              })
                .json()
                .then((response) => {
                  dispatch({ type: SET_LOADER, payload: false });

                  setShowBottom(0);
                  // SendToWhatsapp();
                  return response;
                })

                .then((response) =>
                  client.send(
                    JSON.stringify({
                      response,
                      type: 'neworderevent',
                    })
                  )
                )
                .then(() => setCartItemss([]))
                .then(() =>
                  postData(
                    `${process.env.REACT_APP_BASE_URL}cart/clear`,
                    { user_id: userId },
                    setCartItems,
                    dispatch,
                    'Order added successfully'
                  )
                )
                .catch((error) => {
                  console.log(error, 'error');
                  dispatch({ type: SET_LOADER, payload: false });
                  toast.error(error);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                {cartItemss?.map((item, index) => {
                  return (
                    <div key={index} className='items-card mb-2 '>
                      <img
                        src={
                          'https://zymeal.com/storage/restaurant/logo/default.png'
                        }
                        width='70'
                        height='70'
                        className='item-img'
                      />
                      <div className='item-details'>
                        <p className='common-text'>{item?.item_name}</p>
                        <p>
                          {'\u20B9'} {item?.display_price}
                        </p>
                      </div>
                      <div className='item-quantity'>
                        <p className='common-text'>
                          {item?.quantity} * {item?.display_price}{' '}
                        </p>
                      </div>
                    </div>
                  );
                })}

                <hr className='m-3' />

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className='mx-5 my-3'
                >
                  <p className='common-text'>Grand Total</p>
                  <p className='common-text'>₹ {sum}</p>
                </div>

                <hr className='m-3' />

                <Row className='mt-5'>
                  <SimpleSelect
                    options={delieveryTypeSelect}
                    onChange={handleChange}
                    value={values?.delievery_type}
                    error={touched?.delievery_type && errors?.delievery_type}
                    name='delievery_type'
                    title='Ordering type'
                  />
                  {/* <Form.Label>Ordering type</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFieldValue("delievery_type", e.target.value)
                    }
                    value={values.delievery_type}
                  >
                    <option>Open this select menu</option>
                    <option value="On Table">On Table</option>
                    <option value="Takeaway">Takeaway</option>
                    <option value="Delievery">Delievery</option>
                  </Form.Select> */}
                </Row>

                <Row className='mt-4'>
                  <SimpleSelect
                    options={paymentSelect}
                    onChange={handleChange}
                    value={values?.payment_via}
                    error={touched?.payment_via && errors?.payment_via}
                    name='payment_via'
                    title='Pay Via'
                  />
                  {/* <Form.Label>Pay Via</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFieldValue("payment_via", e.target.value)
                    }
                    value={values.payment_via}
                  >
                    <option>Please select</option>
                    <option value="Pay on Counter">Pay on Counter</option>
                    <option value="Pay Online">Pay Online</option>
                  </Form.Select> */}
                </Row>

                <Row className='mt-4'>
                  <CFormLabel style={{ marginBottom: '-10px' }}>
                    Ordering for
                  </CFormLabel>
                  <SimpleInput
                    title='Your Name'
                    placeholder='Enter your name'
                    disabled
                    value={values.name}
                  />
                  <SimpleInput
                    title='Your Phone Number'
                    placeholder='Enter your phone number'
                    disabled
                    value={values.phone_number}
                  />

                  {values.delievery_type === 'Delievery' ? (
                    <SimpleInput error={touched?.address && errors?.address}>
                      <CFormTextarea
                        placeholder='Enter your address'
                        onChange={(e) =>
                          setFieldValue('address', e.target.value)
                        }
                        value={values.address}
                      />
                    </SimpleInput>
                  ) : (
                    <SimpleInput
                      title='Table No.'
                      placeholder='Enter your table number'
                      onChange={(e) =>
                        setFieldValue('table_no', e.target.value)
                      }
                      value={id ? id : values.table_no}
                      disabled={id}
                    />
                  )}
                </Row>

                <div style={{ textAlign: 'center' }} className='my-4'>
                  <Button
                    type='submit'
                    variant='success'
                    style={{ color: 'white' }}
                  >
                    Save Order
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default FinalOrder;
