import React, { createContext, useState } from "react";

export const RestaurantContext = createContext({
  restaurant: {},
  setRestaurant: () => {},
});

export const RestaurantProvider = ({ children }) => {
  const [restaurant, setRestaurant] = useState({});

  return (
    <RestaurantContext.Provider value={{ restaurant, setRestaurant }}>
      {children}
    </RestaurantContext.Provider>
  );
};
