import React, { createContext, useState } from "react";

export const OrderContext = createContext({
  orderItems: [],
  setOrderItems: () => [],
});

export const OrderContextProvider = ({ children }) => {
  const [orderItems, setOrderItems] = useState([]);

  return (
    <OrderContext.Provider value={{ orderItems, setOrderItems }}>
      {children}
    </OrderContext.Provider>
  );
};
