// we are not storing current employee in the local storage
// as we need to lock on refresh or system off.
export const updateIfShift = (obj, update, employee) => {
  const isShift = localStorage.getItem("open_balance");

  const shift = localStorage.getItem("shift");

  if (isShift) {
    obj.isShift = true;
    obj.emp = employee || null;
    obj.open_balance = isShift;
    if (update) obj.shiftId = JSON.parse(shift)._id;
  }
  return obj;
};
