
import ky from "ky"
import { SET_LOADER } from "src/redux/actions";
import { toast } from "react-toastify";
import { setLocalStorageItem } from "./storage";

function postData(url,dataToSend,setData,dispatch,successMsg,is,...rest) {



    dispatch({ type: SET_LOADER, payload: true });
    ky.post(url,{
      json:dataToSend
    })
      .json()
      .then((response) => {
        dispatch({ type: SET_LOADER, payload: false });
        setData(response?.data);
        console.log(response?.data,"setDataResponse");
        if (is==="isQr") {
          let sData = JSON.stringify(response?.data);
          localStorage.setItem('qrData', sData)
        }
        else if (is === "isCat") {
          let sData = JSON.stringify(response?.data);
          localStorage.setItem('catData',sData)

        }
        toast.success(successMsg);
        rest
      })
      .catch((error) => {
        dispatch({ type: SET_LOADER, payload: false });
        console.log(error, "error")
        toast.error(error)
    }
        );
}



export {postData}