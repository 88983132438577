import { CFormLabel, CFormSelect } from "@coreui/react";
import { useState } from "react";

function SimpleSelect(props) {
  const [isFocused, setIsFocused] = useState(false);

  const {
    value = "",
    onChange = () => {},
    style = {},
    disabled,
    options = [],
    valueAccessor = "value",
    keyAccessor = "key",
    name = "",
    error,
    labelClassName = "",
    title = "",
    required = false,
    className
  } = props;

  // console.log(error, "errorSelect");

  return (
    <div style={{ ...style }}>
      {title ? (
        <CFormLabel className={labelClassName}>
          {title}
          {required ? <span style={{ color: "red" }}> *</span> : null}
        </CFormLabel>
      ) : null}
      <CFormSelect
        aria-label="Default select example"
        onChange={onChange}
        value={value}
        name={name}
        onFocus={() => setIsFocused(true)}
        style={{
          fontSize: "14px",
          color: "black",
          height: "39px",
          border: "1px solid",
          borderRadius: "5px",
          borderColor: error ? "#e55353" : isFocused ? "#35B1A3" : "#D8D8D8",
          boxShadow: "none",
        }}
        className = {className}
      >
        {options?.map((item, index) => (
          <option key={index} value={item[valueAccessor]}>
            {item[keyAccessor]}
          </option>
        ))}
      </CFormSelect>
      {error ? (
        <CFormLabel className="simple-select-error">{error}</CFormLabel>
      ) : null}
    </div>
  );
}

export default SimpleSelect;
