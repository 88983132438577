import { createTheme } from "@mui/material";
import { palette } from "./color";
import { MuiButton } from "./button";

export const theme = createTheme({
  palette,
  components: {
    MuiButton,
  },
});
