import axios from "axios";
import { omit, isEmpty } from "lodash";
import localStorageConstants from "src/constants/localStorageConstants";
import { updateIfShift } from "src/utils/shift";
import { SET_RES_DATA } from "src/views/pages/actions";

export const RESTAURANT_EMPLOYEES_REQUEST = "RESTAURANT_EMPLOYEES_REQUEST";
export const RESTAURANT_EMPLOYEES_SUCCESS = "RESTAURANT_EMPLOYEES_SUCCESS";
export const RESTAURANT_EMPLOYEES_FAILURE = "RESTAURANT_EMPLOYEES_FAILURE";
export const EMPLOYEE_LOGIN_REQUEST = "EMPLOYEE_LOGIN_REQUEST";
export const EMPLOYEE_LOGIN_SUCCESS = "EMPLOYEE_LOGIN_SUCCESS";
export const EMPLOYEE_LOGIN_FAILURE = "EMPLOYEE_LOGIN_FAILURE";

export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAILURE = "EMPLOYEE_CREATE_FAILURE";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAILURE = "EMPLOYEE_DELETE_FAILURE";

export const CURRENT_EMPLOYEE_FAILURE = "CURRENT_EMPLOYEE_FAILURE";
export const CURRENT_EMPLOYEE_SUCCESS = "CURRENT_EMPLOYEE_SUCCESS";
export const CURRENT_EMPLOYEE_REQUEST = "CURRENT_EMPLOYEE_REQUEST";

export const getRestaurantEmployees = (payload) => {
  return async (dispatch, getState) => {
    const { restaurantId } = payload;
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}employees/${restaurantId}/get`)
      .then(async (res) => {
        const response = res.data || [];

        const restaurantDetails = getState()?.auth?.restaurantDetails;

        if (restaurantDetails) {
          restaurantDetails.employees = response;

          await dispatch({
            type: SET_RES_DATA,
            payload: restaurantDetails,
          });
        }
        return response;
      });
  };
};
export const getRestaurantEmployeesSuccess = (payload) => {
  return {
    type: RESTAURANT_EMPLOYEES_SUCCESS,
    payload,
  };
};
export const getRestaurantEmployeesFailure = (payload) => {
  return {
    type: RESTAURANT_EMPLOYEES_FAILURE,
    payload,
  };
};

export const getCurrentEmployee = (payload) => {
  return async (dispatch, getState) => {
    const { pin, open_balance } = payload;
    const restaurantDetails = getState().auth?.restaurantDetails;
    const employees = restaurantDetails?.employees || [];

    const currentEmployee = employees.find(
      (employee) => +employee.pin === +pin
    );
    if (!currentEmployee) {
      return dispatch(getCurrentEmployeeFailure("Wrong Credentials"));
    }

    // for handling navigation after unlock
    payload.callback && payload.callback();
    const shift = localStorage.getItem("shift");
    if (!shift) await createShift(currentEmployee);
    return dispatch(getCurrentEmployeeSuccess(currentEmployee));
  };
};

export const createShift = async (employee) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const { open_balance, emp } = updateIfShift({}, false, employee);
  var date = new Date();
  if (!emp?._id) {
    return;
  }
  let dataToSend = {
    start_time: date.toISOString(),
    user: emp?.name,
    user_id: emp?._id,
    open_balance: open_balance,
    restuarant_id: emp?.restaurantId,
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_URL}shift/start`,
    dataToSend,
    config
  );
  console.log(data, "datShift");

  localStorage.setItem("shift", JSON.stringify(data.shift));
};
export const removeCurrentEmployee = () => {
  return (dispatch) => {
    return dispatch(getCurrentEmployeeSuccess(null));
  };
};

export const getCurrentEmployeeSuccess = (payload) => {
  return {
    type: CURRENT_EMPLOYEE_SUCCESS,
    payload,
  };
};
export const getCurrentEmployeeFailure = (payload) => {
  return {
    type: CURRENT_EMPLOYEE_FAILURE,
    payload,
  };
};

export const createEmployee = (payload) => {
  return async (dispatch, getState) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}employees/add`, payload)
      .then(async (res) => {
        const response = res.data;
        await dispatch(createEmployeeSuccess({ data: response }));
        return response;
      })
      .then(async (response) => {
        const restaurantDetails = getState().auth.restaurantDetails;
        restaurantDetails.employees = restaurantDetails.employees || [];
        restaurantDetails.employees.push(response);

        await dispatch({
          type: SET_RES_DATA,
          payload: restaurantDetails,
        });
        return response;
      })
      .catch((err) => {
        return dispatch(createEmployeeFailure({ error: err }));
      });
  };
};

export const createEmployeeSuccess = (payload) => {
  return {
    type: EMPLOYEE_CREATE_SUCCESS,
    payload,
  };
};
export const createEmployeeFailure = (payload) => {
  return {
    type: EMPLOYEE_CREATE_FAILURE,
    payload,
  };
};

export const deleteEmployee = (payload) => {
  return async (dispatch, getState) => {
    const { _id } = payload;

    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}employees/delete/${_id}`)
      .then(async (res) => {
        const response = res.data;
        await dispatch(deleteEmployeeSuccess({ data: response }));
        return response;
      })
      .then(async (response) => {
        const restaurantDetails = getState().auth.restaurantDetails;
        restaurantDetails.employees = (
          restaurantDetails.employees || []
        ).filter((employee) => employee._id !== _id);

        await dispatch({
          type: SET_RES_DATA,
          payload: restaurantDetails,
        });
        return response;
      })
      .catch((err) => {
        return dispatch(deleteEmployeeFailure({ error: err }));
      });
  };
};

export const deleteEmployeeSuccess = (payload) => {
  return {
    type: EMPLOYEE_DELETE_SUCCESS,
    payload,
  };
};
export const deleteEmployeeFailure = (payload) => {
  return {
    type: EMPLOYEE_DELETE_FAILURE,
    payload,
  };
};
