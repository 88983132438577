export const orderTabValue = {
  all: "All",
  pending: "Pending",
  preparing: "Preparing",
  ready: "Ready",
  dispatch: "Dispatch",
  rejected: "Rejected",
};

const ExtraCharges = [
  {
    key: "Taxes",
    pair: `${"\u20B9"}.78`,
  },
  {
    key: "Services",
    pair: `${"\u20B9"}.78`,
  },
  {
    key: "Delievery Charge",
    pair: `${"\u20B9"}.78`,
  },
  {
    key: "Packaging Charge",
    pair: `${"\u20B9"}.78`,
  },
  {
    key: "Discount",
    pair: `${"\u20B9"}.78`,
  },
];

const orderDetails = [
  {
    delievery_type: "takeaway",
    order_status: orderTabValue.pending,
  },
  {
    delievery_type: "on table",
    order_status: orderTabValue.preparing,
  },
  {
    delievery_type: "delievery",
    order_status: orderTabValue.ready,
  },
];

export const orderTabs = [
  {
    label: "All",
    value: orderTabValue.all,
  },
  {
    label: "Pending",
    value: orderTabValue.pending,
  },
  {
    label: "Preparing",
    value: orderTabValue.preparing,
  },
  {
    label: "Ready",
    value: orderTabValue.ready,
  },
  {
    label: "Dispatch",
    value: orderTabValue.dispatch,
  },
];

export { ExtraCharges, orderDetails };
