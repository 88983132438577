import { createStore } from 'redux'
import {SET_LOADER,CATEGORY_ID} from "./actions";


const initialState = {
  sidebarShow: true,
  loader: false,
  showToggle : true,
  exists:false
}

const commonReducer = (state = initialState, { type,payload,exists,...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
      case SET_LOADER:
        return { ...state, loader: payload };
      case CATEGORY_ID:
        return { ...state, id: payload };
        case "Res_Exists" :
          return { ...state,exists:exists};
      case "SET_Toggle" :
        return { ...state, ...rest};
    default:
      return state
  }
}

export default commonReducer