import React from "react";

import { CForm, CFormInput, CFormLabel } from "@coreui/react";

function SimpleInput(props) {
  const {
    placeholder = "",
    value = "",
    label = "",
    onChange = () => {},
    name = "",
    groupClassName = "",
    style = {},
    type = "",
    disabled,
    isEditable = true,
    required = false,
    error,
    children,
  } = props;

  const renderElement =
    children ||
    (isEditable ? (
      <CFormInput
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        disabled={disabled}
      />
    ) : (
      <div style={{ fontSize: 16.5, fontWeight: 500 }}>
        {value ? value : "-"}
      </div>
    ));

  return (
    <CForm className={groupClassName} style={style}>
      <CFormLabel>{label}</CFormLabel>
      {required ? <span style={{ color: "red" }}> *</span> : null}

      {renderElement}

      {error ? (
        <CFormLabel className="simple-select-error">{error}</CFormLabel>
      ) : null}
    </CForm>
  );
}

export default SimpleInput;
